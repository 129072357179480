import React, { useState, useCallback, useEffect } from "react";
// import CountDownBlock from "./CountDownBlock";


export default () => {
  return (
    <div className="video_cover">
      <iframe width="90%" height="90%" src="https://www.youtube.com/embed/dAT-mi0yfEc?rel=0&autoplay=1" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  );
};
